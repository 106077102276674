import React, { useEffect, useState } from 'react';

// ** MUI Imports
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import ReactApexcharts from 'react-apexcharts';

// ** Icons Imports
import DotsVertical from 'mdi-material-ui/DotsVertical';

// ** Service
import statsService from 'src/services/statsService';

const Sites = () => {
    // ** Hook
    const theme = useTheme();
    const [data, setData] = useState(null);
    const [options, setOptions] = useState({
        "chart": {
            "stacked": true,
            "parentHeightOffset": 0,
            "toolbar": {
                "show": false
            }
        },
        "plotOptions": {
            "bar": {
                "columnWidth": "20%"
            }
        },
        "colors": [
            theme.palette.success.main,
            theme.palette.error.main,
            theme.palette.secondary.main,
            theme.palette.primary.main,
            theme.palette.info.light,
            theme.palette.warning.main,
            theme.palette.grey[300]
        ],
        "grid": {
            "strokeDashArray": 7,
            "borderColor": "rgba(58, 53, 65, 0.12)",
            "padding": {
                "left": 0,
                "bottom": -10
            }
        },
        "legend": {
            "show": true
        },
        "dataLabels": {
            "enabled": false
        },
        "states": {
            "hover": {
                "filter": {
                    "type": "none"
                }
            },
            "active": {
                "filter": {
                    "type": "none"
                }
            }
        },
        "xaxis": {
            "axisTicks": {
                "show": false
            },
            "axisBorder": {
                "show": false
            },
            "categories": [
                "S1",
                "Others"
            ],
            "labels": {
                "style": {
                    "colors": "rgba(58, 53, 65, 0.38)"
                }
            },
            "convertedCatToNumeric": false
        },
        "yaxis": {
            "labels": {
                "offsetY": 2,
                "offsetX": -10,
                "style": {
                    "colors": "rgba(58, 53, 65, 0.38)"
                }
            }
        }
    });

    const path = 'workrequests/summary';

    useEffect(() => {
        statsService.all(path, { export_format: 'apex' })
            .then((res) => {
                setData(res?.data);
                setOptions({ ...options });
            });
    }, []);

    return (
        <Card>
            <CardHeader
                title="Work Requests Overview"
                titleTypographyProps={{
                    sx: { lineHeight: '2rem !important', letterSpacing: '0.15px !important' }
                }}
                action={
                    <IconButton
                        size="small"
                        aria-label="settings"
                        className="card-more-options"
                        sx={{ color: 'text.secondary' }}>
                        <DotsVertical />
                    </IconButton>
                }
            />
            <CardContent>
                {data && <ReactApexcharts
                    type="bar"
                    height={308}
                    options={options}
                    series={data?.series || []}
                />}
            </CardContent>
        </Card>
    );
};

export default Sites;
